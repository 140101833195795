<template>
  <div class="w-full">
    <div class="grid grid-cols-2 gap-4 w-full">
      <BaseInput
        ref="names"
        type="text"
        label="Nombres del representante legal"
        v-model="form.first_name"
        placeholder="Julio Roman"
        :required="true"
        :disabled="disabled"
        :maxlength="25"
      />
      <BaseInput
        ref="last_name"
        type="text"
        label="Apellidos del representante legal"
        v-model="form.last_name"
        placeholder="Henriquez"
        :required="true"
        :disabled="disabled"
        :maxlength="24"
      />
      <BaseInput
        ref="nit"
        type="text"
        id="nit"
        label="NIT"
        placeholder="4893461894-1"
        v-model="form.identification_number"
        identification="3"
        :required="true"
        :disabled="disabled"
        :maxlength="14"
      />
      <BaseInput
        v-if="!disabled"
        ref="nit2"
        type="text"
        id="nit2"
        label="Confirmar NIT"
        placeholder="4893461894-1"
        v-model="form.identification_number_confirmation"
        required="true"
        :maxlength="14"
        identification="3"
        @click.native.right.prevent
        @copy.native.prevent
        @paste.native.prevent
      />
    </div>
    <div class="grid grid-cols-1 gap-4 w-full">
      <BaseInput
        ref="company_name"
        type="text"
        id="razonSocial"
        label="Razón social"
        placeholder="Empresa S.A"
        v-model="form.business_name"
        :required="true"
        :disabled="disabled"
        :maxlength="50"
      />
      <BaseSelect
        ref="phone_country_abrev"
        name="phone_country_abrev"
        id="phone_country_abrev"
        label="País del teléfono"
        :selectOptions="optionsIndicatived"
        v-model="form.phone_country_abrev"
        required="true"
      />
      <BaseInput
        v-if="form.phone_country_abrev != ''"
        ref="phone"
        type="text"
        phoneNumber
        label="Número de teléfono"
        subtitle="(Numero fijo, incluye el indicativo nacional. Ej: 6011234567. Para celulares, ingresa únicamente el número. Ej: 3211234567"
        placeholder="Ej: 3211234567"
        v-model="form.phone"
        :required="true"
        :disabled="disabled"
        :maxlength="15"
      />
      <!-- <div v-if="form.phone !== '' && !validPhone()">
        <p class="mt-5 text-xs text-center text-red-500">{{ feedback }}</p>
      </div> -->
    </div>
    <div class="grid grid-cols-1 gap-4 w-full">
      <BaseRadio
        name="hasEmail"
        id="hasEmail"
        label="¿Tiene correo electrónico?"
        :radioOptions="optionsYN"
        v-model="form.hasEmail"
        required="true"
      />
      <BaseInput
        v-if="form.hasEmail == 1"
        ref="email"
        type="text"
        label="Correo electrónico"
        v-model="form.email"
        placeholder="ejemplo@ejemplo.com"
        required="true"
        autocomplete="username"
        :disabled="disabled"
      />
      <BaseInput
        v-if="form.hasEmail == 1"
        ref="email_confirmation"
        type="text"
        label="Confirmar correo electrónico"
        v-model="form.email_confirmation"
        placeholder="ejemplo@ejemplo.com"
        required="true"
        autocomplete="username"
        :disabled="disabled"
      />
      <BaseInput
        type="text"
        label="Dirección física"
        v-model="form.address"
        placeholder="Calle 123 avenida 123"
        :maxlength="50"        
        @input="validateAddress"
      />
      <p v-if="addressError" class="text-xs text-left text-red-500">{{ addressError }}</p>

      <BaseInput
        v-if="form.hasEmail == 2"
        ref="email"
        type="text"
        label="Correo electrónico de notificación"
        v-model="form.email_notification"
        placeholder="ejemplo@ejemplo.com"        
      />

      <BaseSelect
        ref="department"
        id="department"
        label="Seleccione un departamento"
        :selectOptions="formOptions.optionsDepartments"
        v-model="form.departamento_cod"
        :required="form.hasEmail == 2"
      />
      <BaseSelect
        ref="city"
        id="city"
        label="Seleccione un municipio"
        :selectOptions="optionsMunicipalities"
        v-model="form.municipio_cod"
        :required="form.hasEmail == 2"
      />
    </div>
    <div class="w-full">
      <DragDrop
        class="my-5"
        label="Soportes que acrediten las facultades para interponer quejas o reclamos"
        buttonLabel="SELECCIONAR ARCHIVO DE SOPORTE"
        required="true"
        id="authorizationFile"
        :fileList="fileList"
        @files-handler="setFormFiles"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["fileList", "disabled"],
  data() {
    return {
      addressError: "",
      form: {
        first_name: "",
        last_name: "",
        identification_type: "3",
        identification_number: "",
        identification_number_confirmation: "",
        phone: "",
        phone_country_abrev: "",
        email: "",
        email_confirmation: "",
        business_name: "",
        hasEmail: 1,
        departamento_cod: "",
        municipio_cod: "",
        address: "",
        email_notification: "",
      },
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      optionsMunicipalities: [],
    };
  },
  methods: {
    setFormFiles(files) {
      this.$emit("setFiles", files);
    },
    validateAddress() {            
      if (/[^\s@]+@[^\s@]+\.[^\s@]+/.test(this.form.address)) {
        this.addressError = 'No se permite el ingreso de un correo electrónico en este campo';
      } else {
        this.addressError = '';
      }
    }    
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    optionsIndicatived: function () {
      return this.$store.state.form?.optionsCountriesAbrevs;
    },
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        this.$emit("setForm", {
          ...newVal,
          email: newVal.email.toLowerCase(),
          email_confirmation: newVal.email_confirmation.toLowerCase(),
        });
      },
      deep: true,
    },
    "form.departamento_cod": async function () {
      if (this.form.departamento_cod == "") {
        return;
      }
      this.form.municipio_cod = "";
      let options = await this.$store.dispatch(
        "form/loadMunicipalitiesOptions",
        { department: this.form.departamento_cod }
      );
      this.optionsMunicipalities = options;
    },
  },
};
</script>