<template>
  <div class="w-full">
    <div class="grid grid-cols-1 w-full">
      <BaseInput
        ref="fullName"
        type="text"
        label="Nombre completo"
        v-model="form.first_name"
        placeholder="Ej: Valentina María"
        :required="true"
        :disabled="disabled"
        :maxlength="25"
      />
      <BaseInput
        ref="lastName"
        type="text"
        label="Apellidos"
        v-model="form.last_name"
        placeholder="Ej: Jiménez Gonzalez"
        :required="true"
        :disabled="disabled"
        :maxlength="24"
      />
      <BaseSelect
        ref="identificationType"
        label="Tipo de identificación"
        :selectOptions="customIdentificationType"
        v-model="form.identification_type"
        :required="true"
        :disabled="disabled"
      />
      <BaseInput
        ref="identificationNumber"
        type="text"
        label="Número de identificación"
        placeholder="Ej: 1144578546 (sin puntos)"
        v-model="form.identification_number"
        :required="true"
        :identification="form.identification_type"
        :disabled="disabled"
        :maxlength="15"
      />
      <BaseInput
        v-if="!disabled"
        ref="identification_number_confirmation"
        type="text"
        :identification="form.identification_type"
        label="Confirmar número de identificación"
        placeholder="Ej:123456789"
        v-model="form.identification_number_confirmation"
        required="true"
        @click.native.right.prevent
        @copy.native.prevent
        @paste.native.prevent
      />

      <div v-if="!isValidPpt">
        <p class="mt-5 text-xs text-center text-red-500">{{ feedback2 }}</p>
      </div>
      <BaseDate
        ref="birthDate"
        type="text"
        label="Fecha de nacimiento"
        v-model="form.birth_date"
        placeholder="DD/MM/AAAA"
        :disabled="disabled"
        :disabledDate="disabledDate"
      />

      <BaseSelect
        ref="phone_country_abrev"
        name="phone_country_abrev"
        id="phone_country_abrev"
        label="País del teléfono"
        :selectOptions="optionsIndicatived"
        v-model="form.phone_country_abrev"
        required="true"
      />

      <BaseInput
        v-if="form.phone_country_abrev != ''"
        ref="phone"
        type="text"
        phoneNumber
        maxlength="15"
        label="Número de teléfono"
        subtitle="(Numero fijo, incluye el indicativo nacional. Ej: 6011234567. Para celulares, ingresa únicamente el número. Ej: 3211234567"
        placeholder="Ej: 3211234567"
        v-model="form.phone"
        :disabled="disabled"
        required="true"
      />
    </div>
    <!-- <div v-if="form.phone !== '' && !validPhone()">
      <p class="mt-5 text-xs text-center text-red-500">{{ feedback }}</p>
    </div> -->
    <div class="grid grid-cols-1 gap-4 w-full"></div>
    <div class="grid grid-cols-1 gap-4 w-full">
      <BaseRadio
        name="hasEmail"
        id="hasEmail"
        label="¿Tiene correo electrónico?"
        :radioOptions="optionsYN"
        v-model="form.hasEmail"
        required="true"
      />
      <BaseInput
        v-if="form.hasEmail == 1"
        ref="email"
        type="text"
        label="Correo electrónico"
        v-model="form.email"
        placeholder="ejemplo@ejemplo.com"
        required="true"
        autocomplete="username"
        :maxlength="50"
      />
      <BaseInput
        v-if="form.hasEmail == 1"
        ref="email_confirmation"
        type="text"
        label="Confirmar correo electrónico"
        v-model="form.email_confirmation"
        placeholder="ejemplo@ejemplo.com"
        required="true"
        autocomplete="username"
        :maxlength="50"
      />
    </div>      
    <BaseInput
      type="text"
      label="Dirección física"
      v-model="form.address"
      placeholder="Calle 123 avenida 123"
      :maxlength="50"      
      @input="validateAddress"
    />
    <p v-if="addressError" class="text-xs text-left text-red-500">{{ addressError }}</p>
    
    <BaseInput
        v-if="form.hasEmail == 2"
        ref="email"
        type="text"
        label="Correo electrónico de notificación"
        v-model="form.email_notification"
        placeholder="ejemplo@ejemplo.com"        
      />    
    <BaseSelect
      ref="department"
      id="department"
      label="Seleccione un departamento"
      :selectOptions="formOptions.optionsDepartments"
      v-model="form.departamento_cod"
      :required="form.hasEmail == 2"
    />
    <BaseSelect
      ref="city"
      id="city"
      label="Seleccione un municipio"
      :selectOptions="optionsMunicipalities"
      v-model="form.municipio_cod"
      :required="form.hasEmail == 2"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["disabled"],
  data() {
    return {
      off: "false",
      vv: "",
      feedback: "",
      feedback2: null,
      form: {
        first_name: "",
        last_name: "",
        identification_type: "",
        identification_number: "",
        identification_number_confirmation: "",
        birth_date:"",
        phone: "",
        phone_country_abrev: "",
        email: "",
        email_confirmation: "",
        hasEmail: 1,
        departamento_cod: "",
        municipio_cod: "",
        address: "",
        email_notification: "",
      },
      disabledDate: (current) => {
        // Can not select days after today
        return current && current > moment().endOf("day");
      },
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      optionsMunicipalities: [],
      addressError: ''
    };
  },
  created() {
    this.$emit("setForm", {
      ...this.form,
      email: this.form.email.toLowerCase(),
      email_confirmation: this.form.email_confirmation.toLowerCase(),
    });
  },
  methods: {
    validPpt() {
      if (!this.isValidPpt) {
        this.feedback2 = "Introduzca un número de identificacion válido";

        return false;
      }
      return true;
    },
    validateAddress() {            
      if (/[^\s@]+@[^\s@]+\.[^\s@]+/.test(this.form.address)) {
        this.addressError = 'No se permite el ingreso de un correo electrónico en este campo';
      } else {
        this.addressError = '';
      }
    }
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    isValidPpt: function () {
      if (
        this.form.identification_type != 10 ||
        !this.form.identification_number
      ) {
        return true;
      }
      if (/(?<![.\d])\d+(?![.\d])/.test(this.form.identification_number)) {
        return true;
      }
      return false;
    },
    customIdentificationType: function () {
      return this.$store.state.form?.optionsIdentificationType?.filter(identification => identification.id != 3);
    },
    optionsIndicatived: function () {
      return this.$store.state.form?.optionsCountriesAbrevs;
    }
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (this.validPpt()) this.$emit("setForm", newVal);
      },
      deep: true,
    },
    "form.departamento_cod": async function () {
      if (this.form.departamento_cod == "") {
        return;
      }
      this.form.municipio_cod = "";
      let options = await this.$store.dispatch(
        "form/loadMunicipalitiesOptions",
        { department: this.form.departamento_cod }
      );
      this.optionsMunicipalities = options;
    },
  },
};
</script>